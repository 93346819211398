<template>
  <div class="locker_setting_list sub_new_style01 sub_ui_box1">
    <Search
      codeKey="null"
      :customSearchList="searchType.list"
      :searchObj="searchType.obj"
      @change-search-type="$_changeSearchType"
      @onSearchClick="searchData($event)"
    >
    </Search>
    <esp-dx-data-grid :data-grid="dataGrid" ref="modelGrid" />
    <DxPopup
      :show-title="true"
      :title="modal.title"
      :width="modal.width"
      :height="modal.height"
      :drag-enabled="true"
      :resize-enabled="true"
      :show-close-button="true"
      :close-on-outside-click="false"
      :visible="modal.isOpened"
      @hiding="isOpenModal(false)"
    >
      <template>
        <esp-dx-data-grid :data-grid="expansionGrid" ref="expansionGrid" />
        <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :visible="true"
          :options="{
            elementAttr: {
              class: 'default filled txt_S medium',
            },
            text: '확인',
            //type: 'default',
            width: '120',
            height: '40',
            useSubmitBehavior: true,
            onClick: () => {
              setExpansionId();
            },
          }"
        />
      </template>
    </DxPopup>
  </div>
</template>

<script>
  import Search from '@/components/common/search.vue';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
  import { DxButton } from 'devextreme-vue/button';
  import { DxSwitch } from 'devextreme-vue/switch';
  import { isSuccess } from '@/plugins/common-lib';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: {
      EspDxDataGrid,
      DxSelectBox,
      Search,
      DxPopup,
      DxToolbarItem,
      DxButton,
    },
    props: {
      cmList: {
        type: Array,
      },
    },
    data() {
      return {
        config: {
          pageSetting: {
            //pageData pageSetting 관련
            config: {},
          },
        },
        modal: {
          isOpened: false,
          title: '확장모듈 설정',
          width: '70%',
          height: '650',
          rowData: {},
        },
        expansionList: [],
        rowInfo: {},
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          list: [
            {
              // codeNm: '이름',
              codeNm: this.$_msgContents('COMPONENTS.NAME'),
              codeValue: 'name',
            },
            {
              // codeNm: '설명',
              codeNm: this.$_msgContents('COMPONENTS.DESCRIPTION'),
              codeValue: 'description',
            },
            {
              // codeNm: '라이센스',
              codeNm: this.$_msgContents('UC.WORD.LICENSE'),
              codeValue: 'license',
            },
            {
              // codeNm: '등록자',
              codeNm: this.$_msgContents('COMPONENTS.REGISTRANT'),
              codeValue: 'regId',
            },
          ],
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {},
          paramsData: null,
        },
        codeList: [],
        dataGrid: {
          callApi: 'CALL_EUC_API',
          refName: 'modelGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          height: 'calc(100vh - 350px)', // 주석처리시 100%
          apiActionNm: {
            update: 'EUC_MODEL_UPDATE',
            loading: true,
          },
          customEvent: {},
          showActionButtons: {
            customButtons: [],
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '교환기',
              i18n: 'UC.WORD.EXCHANGER',
              dataField: 'cmNm',
              width: 120,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              calculateCellValue: row => this.getCmNm(row?.cmNm),
            },
            {
              caption: '이름',
              i18n: 'COMPONENTS.NAME',
              dataField: 'name',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowExporing: true,
              fixed: false,
            },
            {
              caption: '설명',
              i18n: 'COMPONENTS.DESCRIPTION',
              dataField: 'description',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '유저등록모델',
              i18n: 'UC.WORD.APUSRASSIGNEDMODEL',
              dataField: 'apusrAssignedModel',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              cellTemplate: (container, options) => {
                const switchBtn = new DxSwitch({
                  propsData: {
                    value: options.value === 'Y',
                    onValueChanged: () => {
                      this.onChangedApUserFl(options.data);
                    },
                  },
                });
                switchBtn.$mount();
                container.append(switchBtn.$el);
              },
            },
            {
              caption: '스마트등록모델',
              i18n: 'UC.WORD.SMARTASSIGNEDMODEL',
              dataField: 'smartAssignedModel',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              cellTemplate: (container, options) => {
                const switchBtn = new DxSwitch({
                  propsData: {
                    value: options.value === 'Y',
                    onValueChanged: () => {
                      this.onChangedSmartFl(options.data);
                    },
                  },
                });
                switchBtn.$mount();
                container.append(switchBtn.$el);
              },
            },

            {
              caption: '사용 여부',
              i18n: 'COMPONENTS.USE_STATUS',
              dataField: 'viewFl',
              width: 150,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              cellTemplate: (container, options) => {
                const switchBtn = new DxSwitch({
                  propsData: {
                    value: options.value === 'Y',
                    onValueChanged: () => {
                      this.onChangedViewFl(options.data);
                    },
                  },
                });
                switchBtn.$mount();
                container.append(switchBtn.$el);
              },
            },
            {
              caption: '순서',
              i18n: 'COMPONENTS.ORDER',
              dataField: 'modelOrd',
              width: 80,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              requiredRule: {
                message: '필수값입니다.',
                i18n: 'COMMON.REQUIRED_VALUE',
              },
            },
            {
              caption: '라이센스',
              i18n: 'UC.WORD.LICENSE',
              dataField: 'license',
              width: 180,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowExporing: true,
              fixed: false,
              fixedPosition: 'left',
              calculateDisplayCellValue: rowData => {
                if (rowData.license == null || rowData.license == 'null') {
                  rowData.license = '';
                  return rowData.license;
                }
                if (rowData?.license) {
                  return rowData.license;
                }
              },
              cellTemplate: (container, options) => {
                const selectbox = new DxSelectBox({
                  propsData: {
                    placeholder: this.$_msgContents('COMPONENTS.SELECT'),
                    items: this.codeList,
                    displayExpr: 'codeNm',
                    valueExpr: 'codeValue',
                    value: options.value,
                    stylingMode: this.stylingMode,
                    onValueChanged: e => {
                      this.$refs.modelGrid.getGridInstance.cellValue(options.rowIndex, options.columnIndex, e.value);
                    },
                  },
                });
                selectbox.$mount();
                container.append(selectbox.$el);
              },
            },
            {
              caption: '확장모듈 설정',
              i18n: 'UC.WORD.EXPANSION_MODULE_SET',
              dataField: 'expansionModId',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              cellTemplate: (container, options) => {
                const button = new DxButton({
                  propsData: {
                    text: '확장모듈 설정',
                    elementAttr: { class: 'btn_XS white light_filled' },
                    height: 30,
                    value: options.value,
                    onClick: async () => {
                      await this.onChangeExapnsionId(options);
                      this.isOpenModal(true);
                    },
                  },
                });
                button.$mount();
                container.append(button.$el);
              },
            },
            {
              caption: '등록자',
              i18n: 'COMPONENTS.REGISTRANT',
              dataField: 'regId',
              width: 120,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '등록일',
              i18n: 'COMPONENTS.REGISTRATION_DATE',
              dataField: 'regDt',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              calculateCellValue: row => this.formatDt(row?.regDt),
            },
          ],
        },
        expansionGrid: {
          callApi: 'CALL_EUC_API',
          refName: 'expansionGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          disableTotalCount: true,
          dataSource: [],
          keyExpr: 'id',
          // width:'200',     // 주석처리시 100%
          height: 'calc(100vh - 350px)', // 주석처리시 100%
          apiActionNm: {
            update: 'EUC_EXPANSION_UPDATE',
            // delete: 'EUC_EXPANSION_DELETE',
            loading: true,
          },
          customEvent: {
            // initNewRow: true,
          },
          showActionButtons: {
            // delete: true,
            customButtons: [],
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '이름',
              i18n: 'COMPONENTS.NAME',
              dataField: 'moduleNm',
              width: 300,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            // {
            //   caption: '타입',
            //   i18n: 'UC.WORD.TYPE',
            //   dataField: 'moduleType',
            //   width: 80,
            //   height: 40,
            //   alignment: 'center',
            //   visible: true,
            //   allowEditing: true,
            //   sortOrder: 'none',
            //   allowHeaderFiltering: false,
            //   allowExporing: true,
            //   fixed: false,
            //   requiredRule: {
            //     message: '필수 항목입니다.',
            //     i18n: 'COMMON.REQUIRED_VALUE',
            //   },
            // },
            {
              caption: '설명',
              i18n: 'COMPONENTS.DESCRIPTION',
              dataField: 'description',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            // {
            //   caption: '엑셀 파일',
            //   i18n: 'UC.WORD.EXCEL_FILE',
            //   dataField: 'excelFile',
            //   width: 120,
            //   height: 40,
            //   alignment: 'center',
            //   visible: true,
            //   allowEditing: false,
            //   sortOrder: 'none',
            //   allowHeaderFiltering: false,
            //   allowGrouping: false,
            // },
            // {
            //   caption: '기타 파일',
            //   i18n: 'UC.WORD.OTHERS_FILE',
            //   dataField: 'etcFile',
            //   width: 120,
            //   height: 40,
            //   alignment: 'center',
            //   visible: true,
            //   allowEditing: false,
            //   sortOrder: 'none',
            //   allowHeaderFiltering: false,
            //   allowGrouping: false,
            // },
            // {
            //   caption: '순서',
            //   i18n: 'COMPONENTS.ORDER',
            //   dataField: 'moduleOrd',
            //   width: 80,
            //   height: 40,
            //   alignment: 'center',
            //   visible: true,
            //   allowEditing: true,
            //   sortOrder: 'none',
            //   allowHeaderFiltering: false,
            //   allowGrouping: false,
            //   requiredRule: {
            //     message: '필수값입니다.',
            //     i18n: 'COMMON.REQUIRED_VALUE',
            //   },
            // },
            {
              caption: '사용 여부',
              i18n: 'COMPONENTS.USE_STATUS',
              dataField: 'viewFl',
              width: 150,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              cellTemplate: (container, options) => {
                const switchBtn = new DxSwitch({
                  propsData: {
                    value: options.value === 'Y',
                    onValueChanged: () => {
                      this.onChangedViewFl(options.data);
                    },
                  },
                });
                switchBtn.$mount();
                container.append(switchBtn.$el);
              },
            },
            // {
            //   caption: '수정일',
            //   i18n: 'COMPONENTS.MODIFY_DATE',
            //   dataField: 'editDt',
            //   width: 150,
            //   height: 40,
            //   alignment: 'center',
            //   visible: true,
            //   allowEditing: false,
            //   sortOrder: 'none',
            //   allowHeaderFiltering: false,
            //   allowGrouping: false,
            //   calculateCellValue: row => this.formatDt(row?.editDt),
            // },
            {
              caption: '등록일',
              i18n: 'COMPONENTS.REGISTRATION_DATE',
              dataField: 'regDt',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              calculateCellValue: row => this.formatDt(row?.regDt),
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      /**@description 사용여부 변경이벤트 */
      async onChangedViewFl(data) {
        const viewFl = data.viewFl === 'Y' ? 'N' : 'Y';

        if (data.id) {
          const payload = {
            actionname: 'EUC_MODEL_UPDATE',
            data: [
              {
                id: data.id,
                viewFl,
              },
            ],
            loading: true,
          };

          const res = await this.CALL_EUC_API(payload);
          if (isSuccess(res)) {
            this.$_Toast(this.$_msgContents('CMN_SUC_UPDATE'));
            this.$refs.modelGrid.getGridInstance.refresh();
          } else {
            this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          }
        } else {
          data.viewFl = viewFl === 'Y';
        }
      },
      /**@description 유저등록모델 사용여부  변경이벤트 */
      async onChangedApUserFl(data) {
        const apusrAssignedModel = data.apusrAssignedModel === 'Y' ? 'N' : 'Y';

        if (data.id) {
          const payload = {
            actionname: 'EUC_MODEL_UPDATE',
            data: [
              {
                id: data.id,
                apusrAssignedModel,
              },
            ],
            loading: true,
          };

          const res = await this.CALL_EUC_API(payload);
          if (isSuccess(res)) {
            this.$_Toast(this.$_msgContents('CMN_SUC_UPDATE'));
            this.$refs.modelGrid.getGridInstance.refresh();
          } else {
            this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          }
        } else {
          data.apusrAssignedModel = apusrAssignedModel === 'Y';
        }
      },
      /**@description 스마트등록모델 사용여부  변경이벤트 */
      async onChangedSmartFl(data) {
        const smartAssignedModel = data.smartAssignedModel === 'Y' ? 'N' : 'Y';

        if (data.id) {
          const payload = {
            actionname: 'EUC_MODEL_UPDATE',
            data: [
              {
                id: data.id,
                smartAssignedModel,
              },
            ],
            loading: true,
          };

          const res = await this.CALL_EUC_API(payload);
          if (isSuccess(res)) {
            this.$_Toast(this.$_msgContents('CMN_SUC_UPDATE'));
            this.$refs.modelGrid.getGridInstance.refresh();
          } else {
            this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          }
        } else {
          data.smartAssignedModel = smartAssignedModel === 'Y';
        }
      },

      /**@description model 목록 가져오는 메서드 */
      async selectDataList(sort = '+modelOrd , +id') {
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);
            if (!params.sort) {
              params.sort = sort;
            }

            params = { ...params, ...this.searchType.paramsData };

            const payload = {
              actionname: 'EUC_MODEL_LIST_ALL',
              data: params,
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;
            }
            return rtnData;
          },
        });
      },
      /**
       * @description 확장모듈리스트
       */
      async selectExpansionList(sort = '+moduleOrd, +id') {
        this.expansionGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);

            params = { ...params, sort: sort, viewFl: 'Y' };
            const payload = {
              actionname: 'EUC_EXPANSION_LIST_ALL',
              data: params,
              loading: true,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;
            }
            this.expansionList = rtnData.data;
            return rtnData;
          },
        });
      },
      getCustomButtons() {
        const buttons = [];

        buttons.push({
          widget: 'dxButton',
          options: {
            icon: '',
            text: this.$_msgContents('UC.WORD.MODEL_SYNC'),
            elementAttr: { class: 'btn_XS default filled ' },
            width: 100,
            height: 30,
            onClick: () => {
              this.onSyncModel();
            },
          },
          location: 'before',
        });

        buttons.push({
          widget: 'dxButton',
          options: {
            icon: '',
            text: this.$_msgContents('UC.WORD.LICENSE_SYNC'),
            elementAttr: { class: 'btn_XS default filled ' },
            width: 120,
            height: 30,
            onClick: () => {
              this.onSyncLicense();
            },
          },
          location: 'before',
        });
        return buttons;
      },
      /** @description : 날짜 형식 변환 메서드 */
      formatDt(date) {
        if (date) {
          return this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
        }
      },
      /** @description : 교환기 이름 변환 메서드 */
      getCmNm(data) {
        let cmNm = '';
        for (let i = 0; i < this.cmList.length; i++) {
          if (this.cmList[i].cmNm === data) {
            cmNm = this.cmList[i].description;
          }
        }
        if (cmNm !== '' && cmNm !== null) {
          return cmNm;
        } else {
          return '';
        }
      },
      /**@description 모델 동기화 */
      async onSyncModel() {
        for (const item of this.cmList) {
          const params = { cmNm: item.cmNm, loginId: this.$store.getters.getLoginId };
          const payload = {
            actionname: 'EUC_MODEL_SYNC',
            data: params,
            loading: true,
          };

          const res = await this.CALL_EUC_API(payload);
          if (isSuccess(res)) {
            // await this.$_Msg('교환기 : ' + item.cmNm + ' ' + this.$_msgContents('COMMON.MESSAGE.CMN_SUCCESS', { defaultValue: '정상적으로 처리되었습니다.' }));
            // await this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_SUCCESS', { defaultValue: '정상적으로 처리되었습니다.' }));
            await this.$refs.modelGrid.getGridInstance.refresh();
          } else {
            await this.$_Msg('교환기 : ' + item.cmNm + ' ' + this.$_msgContents('COMMON.MESSAGE.CMN_ERROR'));
            // await this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_ERROR'));
          }
        }
      },
      /**@description 모델 라이센스동기화 */
      async onSyncLicense() {
        for (const item of this.cmList) {
          const params = { cmNm: item.cmNm, loginId: this.$store.getters.getLoginId, type: 'DN' };
          const payload = {
            actionname: 'EUC_LICENSE_SYNC',
            data: params,
            loading: true,
          };

          const res = await this.CALL_EUC_API(payload);
          if (isSuccess(res)) {
            // await this.$_Msg('교환기 : ' + item.cmNm + ' ' + this.$_msgContents('COMMON.MESSAGE.CMN_SUCCESS', { defaultValue: '정상적으로 처리되었습니다.' }));
            // await this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_SUCCESS', { defaultValue: '정상적으로 처리되었습니다.' }));
            await this.$refs.modelGrid.getGridInstance.refresh();
          } else {
            if (res.data.data.length == 0) {
              await this.$_Msg(res.data.header.resMsg);
            } else {
              await this.$_Msg('교환기 : ' + item.cmNm + ' ' + this.$_msgContents('COMMON.MESSAGE.CMN_ERROR'));
            }
          }
        }
      },
      /**@description IPT CODE 정보 갇져오는 메서드 */
      async selectIptCode(sort = 'codeOrd') {
        const params = { sort: sort, codeKey: 'dnlicense', viewFl: 'Y' };
        const payload = {
          actionname: 'EUC_CODE_LIST_ALL',
          data: params,
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          this.codeList = res.data.data;
          for (let i = 0; i < this.codeList.length; i++) {
            if (this.codeList[i].codeValue == 'dnlicense') {
              this.codeList.splice(i, 1);
            }
          }
        }
      },
      /**@description 커스텀 검색 이벤트 */
      searchData(paramsData) {
        if (paramsData) {
          this.searchType.paramsData = { ...this.searchType.paramsData, ...paramsData };
        } else {
          const codeValues = this.searchType.list.map(d => d.codeValue);
          if (this.searchType.paramsData != null) {
            if (Object.keys(this.searchType.paramsData).length > 0) {
              Object.keys(this.searchType.paramsData).forEach(key => {
                if (codeValues.includes(key)) {
                  delete this.searchType.paramsData[key];
                }
              });
            }
          }
        }
        this.selectDataList();
      },
      /** @description: 팝업이 열렸는지 체크하는 메서드(true: 열림/false: 닫힘) */
      async isOpenModal(data) {
        this.modal.isOpened = data;
        if (data) {
        } else {
          this.$refs.expansionGrid.getGridInstance.clearSelection();
        }
      },
      /** @description 확장모듈 설정 오픈 */
      async onChangeExapnsionId(data) {
        this.modal.rowData = { rowIndex: data.rowIndex, columnIndex: data.columnIndex };
        if (data.data.expansionModId != '' && data.data.expansionModId != null) {
          let ids = data.data.expansionModId.split(',');
          let indexs = ids.map(item => {
            let idx = this.$refs.expansionGrid.getGridInstance.getRowIndexByKey(parseInt(item));
            return idx;
          });
          this.$refs.expansionGrid.getGridInstance.selectRowsByIndexes(indexs);
        }
      },
      /**@description 확장모듈 선택 후 모달 종료 시 셀 데이터 저장 */
      async setExpansionId() {
        let rows = this.$refs.expansionGrid.getGridInstance.getSelectedRowsData();
        let ids = rows.map(d => {
          return d.id;
        });
        this.$refs.modelGrid.getGridInstance.cellValue(this.modal.rowData.rowIndex, this.modal.rowData.columnIndex, ids.join(','));
        await this.isOpenModal(false);
      },
      /** @description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        this.dataGrid.showActionButtons.customButtons = this.getCustomButtons();
      },
      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      async mountData() {
        this.searchType.paramsData = null;
        await this.selectIptCode();
        await this.selectDataList();
        await this.selectExpansionList();
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountData();
    },
  };
</script>
